<template>
    <div id="cart-gift-options" :class="{ loading: addingOptions, mt: true }">
        <p v-if="(isMobile || isLateralMenu) && $scope.EXPERIMENT_VAR3">
            <strong>{{ $t('_shop.giftOrderOpen') }}</strong>
        </p>
        <div v-if="!giftOptions" id="cart-gift-options-loading">
            <div><!--spinner via CSS--></div>
        </div>
        <div v-else id="cart-gift-options-content" class="text-yanone">
            <div
                v-if="
                    $scope.EXPERIMENT_VAR3 === 1 &&
                    $scope.IS_REGULAR_CUSTOMER &&
                    (isMobile || isLateralMenu)
                "
            >
                <button @click.prevent="toggleExpandGifts" class="btn mt open-gifts-button">
                    <p v-if="!showGiftOptions">Ver opções de embalagens especiais</p>
                    <p v-else>Fechar opções de embalagens especiais</p>
                </button>
            </div>
            <template v-if="$scope.IS_REGULAR_CUSTOMER && !isMobile && !isLateralMenu">
                <!--                <div class="section-header">
                    <h3>{{ $t('sizeXY', [10, 15]) }}</h3>
                </div>-->
                <ul id="cart-gift-options-list" class="cart-products-list">
                    <li
                        v-for="option in giftOptions"
                        v-if="option.produto_id !== 11691"
                        class="product-list-item mb-0 product-list-item-top-border"
                    >
                        <div class="cart-gift-option-image">
                            <img
                                :alt="option.name"
                                :src="option.image"
                                :data-value="`{product_id: ${option.produto_id},variation_id:1034,quantity:1}`"
                                :id-value="option.produto_id"
                                :zoom-src="`${$scope.CDN_PRODUCTS}/${option.image}`"
                                class="img-responsive no-border"
                                height="213px"
                                width="141px"
                            />
                        </div>
                        <div class="d-flex flex-column mt">
                            <div class="text-bold special-option-text">
                                {{ option.name }}
                            </div>
                            <span>
                                {{ option.size_name }}
                            </span>
                            <div class="d-flex prices-wrap text-bold">
                                <p>
                                    {{ $f.formatMoney(option.price) }}
                                </p>
                            </div>
                            <div class="hidden-xs-down">
                                <div
                                    v-if="
                                        (maximumPackings > 0 && option.type_id === 13) ||
                                        option.type_id !== 13
                                    "
                                    class="d-flex mt-3 flex-align-center select-and-prices"
                                >
                                    <div class="form-group">
                                        <div class="cr-select mt-3 mr-2">
                                            <select
                                                v-if="maximumPackings > 0 && option.type_id === 13"
                                                v-model="option.quantity"
                                            >
                                                <option v-for="(packing, key) in maximumPackings">
                                                    {{ key + 1 }}
                                                </option>
                                            </select>
                                            <select v-else v-model="option.quantity">
                                                <option v-for="(packing, key) in 5">
                                                    {{ key + 1 }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <button
                                        style="
                                            background-color: white;
                                            color: black;
                                            font-size: 13px;
                                            border: 1.8px solid;
                                            border-radius: unset;
                                            width: auto;
                                        "
                                        class="btn btn-wide mt-0 px-4 special-option-button"
                                        @click="addToCart(option)"
                                    >
                                        ADICIONAR
                                    </button>
                                </div>
                                <div v-else class="alert-warning mt">
                                    Apenas 1 embalagem por produto da sacola.
                                </div>
                            </div>
                            <div
                                class="hidden-xs-up flex-align-center flex-justify-between select-and-prices-special mt-1"
                                style="width: auto"
                            >
                                <div class="form-group" style="margin-bottom: 0 !important">
                                    <div class="cr-select mr-2">
                                        <select v-model="option.quantity">
                                            <option v-for="(quantity, key) in 5">
                                                {{ key + 1 }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div>
                                    <button
                                        class="btn btn-wide mt-0 px-4 special-option-button"
                                        @click="addToCart(option)"
                                    >
                                        ADICIONAR
                                    </button>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </template>
            <!--isso sera 1 teste ab-->
            <template
                v-else-if="
                    $scope.IS_REGULAR_CUSTOMER && ($scope.EXPERIMENT_VAR3 !== 1 || showGiftOptions)
                "
            >
                <div>
                    <div class="d-flex w-full">
                        <ul
                            class="gift-options-ab product-horizontal-list product-horizontal-list-3 no-scrollbar"
                            style="flex: 100%"
                            :class="{
                                'mt-5': $scope.EXPERIMENT_VAR3,
                            }"
                            @scroll="scrollEvent = $event"
                            id="gift-options-cart-list"
                        >
                            <div
                                v-for="option in giftOptions"
                                v-if="option.produto_id !== 11691"
                                class="d-flex flex-column"
                            >
                                <div class="pos-relative">
                                    <img
                                        :alt="option.name"
                                        :src="option.image"
                                        :data-value="`{product_id: ${option.produto_id},variation_id:1034,quantity:1}`"
                                        :id-value="option.produto_id"
                                        :zoom-src="`${$scope.CDN_PRODUCTS}/${option.image}`"
                                        class="img-responsive no-border"
                                    />
                                    <div class="icon-add-to-cart">
                                        <i
                                            class="icon-cr icon-list-add-to-cart"
                                            @click="addToCart(option)"
                                        ></i>
                                    </div>
                                </div>

                                <div
                                    class="d-flex flex-column flex-align-start flex-justify-around mt"
                                >
                                    <div class="text-bold special-option-text">
                                        {{ option.name }}
                                    </div>
                                    <span>
                                        {{ option.size_name }}
                                    </span>
                                    <div class="d-flex prices-wrap text-bold">
                                        <p>
                                            {{ $f.formatMoney(option.price) }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </ul>
                    </div>
                    <HorizontalScrollbar
                        v-if="giftOptions.length > 2"
                        :scroll-event="scrollEvent"
                        id-list="gift-options-cart-list"
                    />
                </div>
            </template>

            <Alert ref="cartGiftOptionsAlert" class="mt" />
        </div>
    </div>
</template>

<script>
    import { getScope } from '../../../common/common';
    import Alert from '../common/Alert';
    import { cartService } from '../../../common/service/resource';
    import HorizontalScrollbar from '../common/container/HorizontalScrollbar.vue';

    export default {
        name: 'CartGiftOptions',

        components: { HorizontalScrollbar, Alert },

        props: {
            showPacking: Boolean,
            maximumPackings: Number,
            isLateralMenu: Boolean,
            isMobile: Boolean,
        },

        data() {
            return {
                giftOptions: null,
                zoomedCard: null,
                addingOptions: false,
                scrollEvent: null,
                showGiftOptions: false,
            };
        },

        mounted() {
            this.fetchData();
        },

        methods: {
            fetchData: function () {
                this.axios.http
                    .get(this.$scope.API_LAMBDA_URL + '/products/v4/search', {
                        params: {
                            onlyInStock: true,
                            listLocked: true,
                            attributes: 'gift-box',
                            orderBy: 'tipo',
                            wholesaler: !this.$scope.IS_REGULAR_CUSTOMER,
                            types: '12,13,21',
                            perPage: 40,
                            onlyActive: true,
                            tenant: this.$scope.TENANT_IDENTIFIER,
                        },
                    })
                    .then((response) => {
                        this.giftOptions = response.data.hits
                            .filter((option) => !option.is_gift)
                            .map((option) => {
                                let imageParams = 'w=160&auto=compress,format&q=65';

                                return {
                                    produto_id: option.id,
                                    image: `${option.img_cover}?${imageParams}`,
                                    biggerImage: `${option.img_cover}?auto=compress,format&q=65`,
                                    name: option.name,
                                    price: option.price,
                                    type: (option.type || {}).name,
                                    type_id: (option.type || {}).id,
                                    selected: false,
                                    variation_id: option.variations[0].variation_id,
                                    size_name: option.variations[0].size_name,
                                    quantity: 1,
                                };
                            });
                    })
                    .catch((error) => {
                        this.showErrorAlert('Não foi possível carregar as opções.');
                    });
            },

            buildCartBodyRequest: function (product) {
                return {
                    products: [
                        {
                            product_id: product.produto_id,
                            variation_id: product.variation_id,
                            quantity: product.quantity,
                        },
                    ],
                };
            },

            sendToCart: function (requestBody) {
                return cartService.updateProducts(getScope('cart_id'), requestBody);
            },

            closeCartGiftOptions: function (refreshCart = false) {
                this.$emit('hide-gifts');
                if (refreshCart) this.$emit('update-cart');
            },

            showErrorAlert: function (message) {
                if (this.$refs.cartGiftOptionsAlert) {
                    this.$refs.cartGiftOptionsAlert.updateAlert(message, true);
                }
            },

            handleCartErrorResponse: function (response) {
                try {
                    response = response.response.data;
                    let message = response.errors[0][0];

                    for (let i = 1; i < response.errors.length; i++) {
                        if (message.indexOf(response.errors[i][0]) < 0) {
                            message += '; ' + response.errors[i][0];
                        }
                    }

                    this.showErrorAlert(message);
                } catch (error) {
                    this.showErrorAlert('Não foi possível adicionar as opções à sacola.');
                }
            },

            addToCart: function (product) {
                this.addingOptions = true;
                const requestBody = this.buildCartBodyRequest(product);
                this.sendToCart(requestBody)
                    .then(() => {
                        this.$emit('gift-quantity-changed', product, 1);
                        this.closeCartGiftOptions(true);
                    })
                    .catch(this.handleCartErrorResponse)
                    .then(() => (this.addingOptions = false));
            },

            toggleExpandGifts() {
                this.showGiftOptions = !this.showGiftOptions;
            },
        },
    };
</script>
