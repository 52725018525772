<template>
    <div>
        <Advantages :hide-promo-banner="hidePromoBanner" hide-regulation="true" />
        <section id="product" itemscope itemtype="https://schema.org/Product">
            <meta :content="productDescriptionPlain" itemprop="description" />
            <meta
                :content="
                    (product.img_cover ? product.img_cover : product.img_thumb) +
                    '?auto=compress,format&q=65'
                "
                itemprop="image"
            />
            <meta content="Chico Rei" itemprop="ag" />
            <meta content="Chico Rei" itemprop="brand" />
            <meta :content="product.id" itemprop="sku" />

            <div itemprop="offers" itemscope itemtype="http://schema.org/Offer">
                <link
                    :href="
                        'http://schema.org/' +
                        (product.quantity > 0 || product.virtual_stock ? 'InStock' : 'OutOfStock')
                    "
                    itemprop="availability"
                />
                <meta content="BRL" itemprop="priceCurrency" />
                <link href="http://schema.org/NewCondition" itemprop="itemCondition" />
                <link :href="product.link" itemprop="url" />
                <meta
                    :content="product.price_min > 0 ? product.price_min : product.price"
                    itemprop="price"
                />
            </div>

            <article>
                <div class="content">
                    <input :value="product.id" name="produto_id" type="hidden" />
                    <ProductBreadcrumb :product="product" />
                </div>

                <div id="product-main-wrapper" class="content">
                    <div id="product-main" class="flex-grid">
                        <ProductTitle :product="product" :isMobile="true" />

                        <div class="flex-xs-12 flex-lg-8">
                            <ProductImages v-if="product" :product="product" />
                        </div>
                        <div class="flex-xs-12 flex-lg-4 productBuyInfos">
                            <div class="product-main-info">
                                <div id="buyButton" ref="buyButton" class="anchor" />
                                <div id="product-main-right">
                                    <ProductMainInfo
                                        :can-be-bought="canBeBought"
                                        :partner="product.partner"
                                        @scroll-to-wholesaler-buy="scrollToWholesalerBuy"
                                    />
                                    <div id="product-info">
                                        <template v-if="$scope.IS_REGULAR_CUSTOMER">
                                            <ProductBuy :can-be-bought="canBeBought" />
                                        </template>
                                        <ProductAboutForWholesaler
                                            v-else
                                            class="hidden-opened"
                                            :product="product"
                                            :partner="product.partner"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="content pb-3" v-if="$scope.IS_REGULAR_CUSTOMER && $scope.basicsKit">
                    <BasicsKit :product="$scope.basicsKit" :price="product.price" />
                </div>

                <div ref="wholesalerBuyAnchor" class="anchor" />

                <div class="content rmc mb mt" v-if="!isMobile">
                    <div class="mb-5 hidden-opened">
                        <template
                            class="mt"
                            v-if="
                                $scope.IS_REGULAR_CUSTOMER &&
                                product.variations.some((v) => v.product_model_id)
                            "
                        >
                            <div id="table-sizes-anchor" class="anchor"></div>
                            <h2
                                class="h6 text-bold text-default-5 text-uppercase font-roboto-c mt grayColor m0 hidden-md-up"
                                @click="collapseItens.table = !collapseItens.table"
                            >
                                <i
                                    class="icon-cr collapsible-icon hidden-md-up"
                                    :class="
                                        collapseItens.table
                                            ? 'icon-chevron-right'
                                            : 'icon-chevron-down-2'
                                    "
                                ></i>
                                Tabela de tamanhos
                            </h2>
                            <div class="content rmc">
                                <div
                                    id="size-table-gray"
                                    :class="{ opened: collapseItens.table }"
                                    class="grayColor"
                                >
                                    <ProductSizesTable :product="product" />
                                </div>
                            </div>
                        </template>
                    </div>
                </div>

                <div class="content pb-5">
                    <Lazy>
                        <ProductCombo :product="product" />
                    </Lazy>
                </div>

                <div
                    :id="$scope.IS_REGULAR_CUSTOMER ? 'product-about' : 'product-buy'"
                    class="frame px-0 m0 p0"
                >
                    <div class="content rmc">
                        <hr class="anchorSpace anchor hidden-md-down" />
                        <ProductAboutForCustomer
                            v-if="$scope.IS_REGULAR_CUSTOMER"
                            class="mb hidden-opened"
                            :product="product"
                            :partner="product.partner"
                            :show-product-video="(product.partner || {}).id !== 263"
                        />
                        <div v-else>
                            <h2 class="font-roboto-c">
                                {{ $t('_shop.selectWhatAddPurchase') }}
                            </h2>
                            <ProductBuy
                                :can-be-bought="canBeBought"
                                @show-sizes-table="showTableSizesModal = true"
                            />
                        </div>
                    </div>
                </div>
                <div class="content rmc mb mt" v-if="isMobile">
                    <div class="mb-5 hidden-opened">
                        <template
                            class="mt"
                            v-if="
                                $scope.IS_REGULAR_CUSTOMER &&
                                product.variations.some((v) => v.product_model_id)
                            "
                        >
                            <div id="table-sizes-anchor" class="anchor"></div>
                            <h2
                                class="h6 text-bold text-default-5 text-uppercase font-roboto-c mt grayColor m0 hidden-md-up"
                                @click="collapseItens.table = !collapseItens.table"
                            >
                                <i
                                    class="icon-cr collapsible-icon hidden-md-up"
                                    :class="
                                        collapseItens.table
                                            ? 'icon-chevron-right'
                                            : 'icon-chevron-down-2'
                                    "
                                ></i>
                                Tabela de tamanhos
                            </h2>
                            <div class="content rmc">
                                <div
                                    id="size-table-gray"
                                    :class="{ opened: collapseItens.table }"
                                    class="grayColor"
                                >
                                    <ProductSizesTable :product="product" />
                                </div>
                            </div>
                        </template>
                    </div>
                </div>

                <div class="content rmc mb mt">
                    <hr class="anchorSpace anchor hidden-md-down" />
                    <div class="mb-5 hidden-opened">
                        <div class="content">
                            <Lazy v-if="!$scope.IS_SALES_REP && (product.partner || {}).id !== 263">
                                <Recommendations
                                    class="pb-5 mt"
                                    :params="recommendationsParams"
                                    :item-options="{
                                        displayPercent: false,
                                        hideInstallments: true,
                                        hidePromoBadge: true,
                                    }"
                                    :slider-options="shirtsSliderOptions"
                                    list-event-name="Recomendações produto"
                                />
                            </Lazy>
                        </div>
                    </div>
                    <div class="content" id="creator-other-products-ab">
                        <Lazy
                            v-if="
                                !$scope.IS_SALES_REP &&
                                (product.partner || {}).id !== 263 &&
                                product.partner.other_products
                            "
                        >
                            <ProductCreatorOtherProducts
                                class="pb-5"
                                :partner="product.partner"
                                :products="product.partner.other_products"
                            />
                        </Lazy>
                    </div>
                </div>

                <template v-if="$scope.IS_REGULAR_CUSTOMER && (product.partner || {}).id !== 263">
                    <CrProductReviews :product="product" @zoom="zoomPhoto" />
                </template>

                <Modal
                    v-if="
                        !$scope.IS_REGULAR_CUSTOMER &&
                        product.variations.some((v) => v.product_model_id)
                    "
                    v-model="showTableSizesModal"
                    class="small"
                >
                    <template v-slot:title>Medidas</template>
                    <div class="card-block">
                        <ProductSizesTable :grid-layout="false" :product="product" />
                    </div>
                </Modal>

                <Modal
                    v-if="$scope.id && hasVariations && $scope.IS_REGULAR_CUSTOMER"
                    v-model="showWishlist"
                >
                    <template v-slot:title>{{ $t('_shop._wishlist.add') }}</template>
                    <div class="card-block">
                        <ProductWishlist v-if="showWishlist" @done="showWishlist = false" />
                    </div>
                </Modal>

                <Modal v-if="hasVariations" v-model="showReprint">
                    <template v-slot:title>Não encontrou seu tamanho?</template>
                    <div class="card-block">
                        <ProductReprint v-if="showReprint" @done="showReprint = false" />
                    </div>
                </Modal>

                <div class="pos-fixed pos-all" style="z-index: 1000" v-if="zoomedPhoto">
                    <div
                        class="pos-absolute pos-all bg-default-5 fade-4"
                        @click="zoomedPhoto = null"
                    />
                    <div class="pos-absolute pos-center border-radius overflow-hidden">
                        <img
                            :src="zoomedPhoto"
                            class="d-block"
                            style="max-width: 80vw; max-height: 80vh"
                        />
                    </div>
                </div>

                <SalesRepCartCustomerManager />
            </article>
        </section>
    </div>
</template>

<script>
    import {
        EventBus,
        readUrlQuery,
        resizeOrientationAware,
        updateLazyLoad,
    } from '../../../common/common';
    import cr$ from '../../../common/crquery';
    import { productStore } from '../../common/store/product-store';
    import Advantages from '../common/Advantages';
    import Lazy from '../common/Lazy';
    import Modal from '../common/container/Modal';
    import SalesRepCartCustomerManager from '../sales-rep/SalesRepCartCustomerManager';
    import ProductAboutForCustomer from './ProductAboutForCustomer';
    import ProductAboutForWholesaler from './ProductAboutForWholesaler';
    import ProductBreadcrumb from './ProductBreadcrumb';
    import ProductBuy from './ProductBuy';
    import ProductCreatorOtherProducts from './ProductCreatorOtherProducts';
    import ProductImages from './ProductImages';
    import ProductMainInfo from './ProductMainInfo';
    import ProductReprint from './ProductReprint';
    import ProductSizesTable from './ProductSizesTable';
    import ProductWishlist from './ProductWishlist';
    import ProductTitle from './ProductTitle';
    import CrProductReviews from './CrProductReviews.vue';
    import AvaliacoesShowStars from '../avaliacoes/AvaliacoesShowStars.vue';
    import ProductCombo from './ProductCombo.vue';
    import ProductPageComboPrice from './ProductPageComboPrice.vue';
    import { initModals } from '../../../common/cr-components';
    import ProductSelectVariations from './ProductSelectVariations.vue';
    import BasicsKit from './BasicsKit.vue';

    export default {
        name: 'ProductPage',

        components: {
            BasicsKit,
            ProductSelectVariations,
            ProductPageComboPrice,
            ProductCreatorOtherProducts,
            ProductReprint,
            ProductWishlist,
            SalesRepCartCustomerManager,
            Modal,
            ProductAboutForWholesaler,
            ProductAboutForCustomer,
            ProductMainInfo,
            Advantages,
            Lazy,
            ProductSizesTable,
            ProductBuy,
            ProductImages,
            ProductBreadcrumb,
            ProductTitle,
            Recommendations: () =>
                import(/* webpackChunkName: "chunk/recommendations" */ '../common/Recommendations'),
            CrProductReviews,
            AvaliacoesShowStars,
            ProductCombo,
        },

        data() {
            return {
                productStoreState: productStore.state,
                collapseItens: {
                    table: true,
                },
                showTableSizesModal: false,

                shirtsSliderOptions: {
                    slidesPerView: 2,
                    fitSlidesToParent: true,
                    onNavigate: () => updateLazyLoad(),
                    responsive: [
                        {
                            minWidth: 565,
                            settings: {
                                slidesPerView: 3,
                            },
                        },
                        {
                            minWidth: 960,
                            settings: {
                                slidesPerView: 5,
                            },
                        },
                    ],
                },
                zoomedPhoto: null,
                isMobile: false,
            };
        },

        created() {
            productStore.setProduct(this.$scope.ELASTIC_PRODUCT);

            EventBus.$on('open-collapside-size-table', this.openSizeTable);
        },

        mounted() {
            const modalParam = readUrlQuery('modal');
            if (modalParam) {
                const variationId = readUrlQuery('size');

                if (this.hasVariations && modalParam === 'Wishlist') {
                    if (variationId) {
                        this.sizeToWish = this.product.variations.find(
                            (s) => s.variation_id === parseInt(variationId)
                        );
                    } else {
                        this.sizeToWish = this.product.variations[0];
                    }

                    this.showWishlist = true;
                } else if (this.hasVariations && modalParam === 'Reprint') {
                    if (variationId) {
                        this.sizeToReprint = this.product.variations.find(
                            (s) => s.variation_id === parseInt(variationId)
                        );
                    } else {
                        this.sizeToReprint = this.product.variations[0];
                    }

                    this.showReprint = true;
                }
            }

            this.resizeAwareRemoveCb = resizeOrientationAware(() => {
                this.isMobile = window.innerWidth < 768;
            }, true);
        },
        beforeDestroy() {
            this.resizeAwareRemoveCb();
        },

        computed: {
            product() {
                return this.productStoreState.product;
            },

            productDescriptionPlain() {
                if (!this.product) {
                    return null;
                }

                return this.$f
                    .removeInlineStyles(this.product.description || '')
                    .replace(/(<([^>]+)>)/gi, '');
            },

            relatedPartnerProductsIds() {
                if (
                    !this.product ||
                    !this.product.partner ||
                    !this.product.partner.other_products
                ) {
                    return '';
                }

                return this.product.partner.other_products.map((p) => p.id).join(',');
            },

            recommendationsParams() {
                return {
                    scenario: 'product-page',
                    rotationRate: 0.1,
                    minRelevance: 'medium',
                    list: 'Recomendações página Produto ',
                    product_id: this.$scope.ELASTIC_PRODUCT.id,
                    impact_user: false,
                    ignore_products: this.relatedPartnerProductsIds,
                };
            },

            hasVariations() {
                return this.product.variations.length > 0;
            },

            showWishlist: {
                get() {
                    return this.productStoreState.showWishlist;
                },

                set(val) {
                    this.productStoreState.showWishlist = val;
                },
            },

            showReprint: {
                get() {
                    return this.productStoreState.showReprint;
                },

                set(val) {
                    this.productStoreState.showReprint = val;
                },
            },

            sizeToWish: {
                get() {
                    return this.productStoreState.sizeToWish;
                },

                set(val) {
                    this.productStoreState.sizeToWish = val;
                },
            },

            sizeToReprint: {
                get() {
                    return this.productStoreState.sizeToReprint;
                },

                set(val) {
                    this.productStoreState.sizeToReprint = val;
                },
            },

            hidePromoBanner() {
                return (
                    !this.product ||
                    (this.product.partner && this.product.partner.id === 140) ||
                    !this.product.promo_combo
                );
            },

            canBeBought() {
                return this.product.can_be_bought;
            },
        },

        watch: {
            showWishlist(to) {
                if (to && !this.$scope.id) {
                    this.redirectToLogin('Wishlist', this.sizeToWish || this.sizeToBuy);
                }
            },

            showReprint(to) {
                if (to && !this.$scope.id) {
                    this.redirectToLogin('Reprint', this.sizeToReprint || this.sizeToBuy);
                }
            },
        },

        methods: {
            scrollToWholesalerBuy() {
                cr$.byEl(this.$refs.wholesalerBuyAnchor).scrollIntoView();
            },

            redirectToLogin(backModal, size) {
                let backUri = this.$scope.REQUEST_URI;
                if (backUri.indexOf('/') === 0) {
                    backUri = backUri.substring(1);
                }

                // todo: melhorar implementação usando url.searchParams
                let url = '/entrar?back=';

                if (backUri.indexOf('?') >= 0) {
                    url += `${backUri.substr(0, backUri.indexOf('?'))}?modal=${backModal}`;
                } else {
                    url += `${backUri}?modal=${backModal}`;
                }

                if (size) {
                    url += `&size=${size.variation_id}`;
                }

                window.location.href = url;
            },

            openSizeTable() {
                this.collapseItens.table = false;
            },

            zoomPhoto(image) {
                this.zoomedPhoto = image;
            },
        },
    };
</script>
